

































import { Component, Provide, Watch, Vue } from 'vue-property-decorator';
import { getPaymentDetail } from '@/apis/payment.api';
import { Payment } from '@/dtos/Account';
import { mounted } from "@/utils/decorators/VueTimmer";
import { listPlan } from '@/apis/plan.api';
import { keyBy, fromPairs, mapValues  } from 'lodash';
import { PlanDetail } from '@/dtos/Account';

@Component({
  props: {
    id: {
      type: String,
    }
  }
})
export default class PaymentDetailNewPlanCard extends Vue {
  id: string;
  payment: Payment = null;

  @Provide()
  accountPlan: Record<string, PlanDetail> = {};

  @Watch('id')
  @mounted
  getPaymentDetail() {
    if (!this.id) {
      return;
    }

    return getPaymentDetail(this.id)
      .then(payment => {
        this.payment = payment;
      });
  }

  @mounted
  getListPlan() {
    listPlan().then(e => (this.accountPlan = keyBy(e, 'currency_code')));
  }
}
