




















































import { Component, Watch, Vue } from 'vue-property-decorator';
import { getPaymentDetail } from '@/apis/payment.api';
import { Payment } from '@/dtos/Account';
import { mounted } from "@/utils/decorators/VueTimmer";

@Component({
  props: {
    id: {
      type: String,
    }
  }
})
export default class PaymentDetailCard extends Vue {
  id: string;
  payment: Payment = null;

  @Watch('id')
  @mounted
  getPaymentDetail() {
    if (!this.id) {
      return;
    }

    return getPaymentDetail(this.id)
      .then(payment => {
        this.payment = payment;
      });
  }
}
