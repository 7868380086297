











import { Component, Prop, Vue, Provide } from "vue-property-decorator";
import PaymentDetailCard from './PaymentDetailCard.vue';
import PaymentDetailPrePlanCard from './PaymentDetailPrePlanCard.vue';
import PaymentDetailNewPlanCard from './PaymentDetailNewPlanCard.vue';

@Component({
  components: {
    PaymentDetailCard,
    PaymentDetailPrePlanCard,
    PaymentDetailNewPlanCard
  }
})
export default class PaymentDetailPage extends Vue {
  get currentPaymentId(): string {
    return this.$route.query.payment_id as string;
  }
 
}
